import themeMedia from './theme-media'

themeMedia()

Promise.all(
    [
        import('./modal'),
    ]
).then(
    (
        [
            modal,
        ]
    ) => {
        modal.default()
    }
)